import * as React from "react"
const Aboutpage = () => {
    return (
        <main>
          <title>About Me</title>
          <h1>About Me</h1>
          <p>Hi there! I'm the proud creator of this site, which I built with Gatsby.</p>
        </main>
      )
}

// Step 3: Export your component
export default Aboutpage
